.campaign-update-card {
  border: 1px solid #D8D8D8;
  border-radius: 4px;
  padding: 12px;
  &.disburse {
    background: #e7f5ff;
  }
  &__publisher {
    &-avatar {
      height: 40px;
      width: 40px;
      margin-right: 12px;
      border-radius: 40px;
    }
    &-info {
      display: flex;
      align-items: center;
      &-detail {
        display: flex;
        flex-direction: column;
      }
    }
    &-name {
      font-family: 'maison_neuedemi', sans-serif;
      color: #4A4A4A;
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 4px;
    }
    &-date {
      font-family: 'maison_neuebold', sans-serif;
      font-size: 10px;
      line-height: 13px;
      color: #999999;
    }
  }
  &__content {
    img {
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }
}