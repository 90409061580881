@import "partials/sanitize.scss";
@import "partials/variables.scss";
@import "partials/slick.scss";
@import "partials/slick-theme.scss";

* {
  box-sizing: border-box;
}

a {
  &:focus,
  &:active {
    outline: none
  }
  color: #4a4a4a;
  text-decoration: none;
}

body {
  color: #4A4A4A;
  font-family: 'Open Sans', sans-serif;
  background: #ffffff;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

button {
  font: inherit;
  &:focus {
    outline: none
  }
}

section {
  margin-bottom: 25px;
  h3 {
    font-size: 16px;
    line-height: 21px;
    color: #4A4A4A;
    margin: 4px 0;
  }
  .subtitle {
    font-size: 12px;
    line-height: 16px;
    display: block;
  }
  img {
    max-width: 100%;
    width: 100%;
  }
}
section.fullwidth {
  margin: 0 -16px;
}

.slick-slide {
  padding: 0 5px;
}
.slick-initialized {
  &.slick-slider{
    max-width: 480px;
    margin: 0 auto;
  }
  .slick-slide {
    display: block;
  }
}

.Modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1111;
}

.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden; /* prevents background page from scrolling when the modal is open */
}

.ReactModal__Overlay {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Content {
  background: white;
  // width: 50rem;
  // max-width: calc(100vw - 2rem);
  max-height: 100vh;
  // box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}

.modal-close-btn {
  cursor: pointer;
  top: 1.5rem;
  right: 1.5rem;
  position: absolute;
  width: 3rem;
  height: 3rem;
}

