$font-size: 12px;
$line-height: 1.2;
$lines-to-show: 2;

.card-small--column {
  width: 100%;
  display: flex;
  padding: 16px;
  position: relative;
  background: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(232, 232, 232);
  &__image-container {
    margin-right: 16px;
    max-width: 110px;
    flex: 1 1 0%;
  }
  &__image {
    width: 100%;
    border-radius: 4px;
  }
  &__meta {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    flex: 1 1 0%;
  }
  &__title {
    font-weight: 500;
    font-size: 14px;
    color: rgb(74, 74, 74);
    margin-bottom: 0.25em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &__action {
    display: none;
  }
  &__donation-collected {
    display: none;
  }
  &__campaigner {
    display: flex;
    width: 100%;
    vertical-align: middle;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.8);
    -webkit-box-align: center;
    align-items: center;
    margin: 0.25em 0em;
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    img {
      height: 12px;
      margin-left: 0.25em;
    }
  }
}
.card-medium--column {
  width: 100%;
  display: flex;
  padding: 20px 0;
  position: relative;
  background: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(232, 232, 232);
  &__image-container {
    margin-right: 16px;
    max-width: 110px;
    flex: 1 1 0%;
  }
  &__image {
    width: 100%;
    border-radius: 4px;
  }
  &__meta {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    flex: 1 1 0%;
  }
  &__title {
    font-weight: 500;
    font-size: 14px;
    color: rgb(74, 74, 74);
    margin-bottom: 0.25em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &__action {
    display: none;
  }
  &__campaigner {
    display: flex;
    width: 100%;
    vertical-align: middle;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.8);
    -webkit-box-align: center;
    align-items: center;
    margin: 0.25em 0em;
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    img {
      height: 12px;
      margin-left: 0.25em;
    }
  }
}
.card-fullwidth {
  width: 100%;
  display: flex;
  padding: 16px 0;
  position: relative;
  background: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(232, 232, 232);
  &__image-container {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    z-index: 0;
    margin: 0px 20px 0px 0px;
    flex: 1 1 0%;
  }
  &__image {
    width: 100%;
    border-radius: 4px;
  }
  &__meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 1 0%;
    &-info {
      width: 100%;
    }
  }
  &__title {
    font-weight: 500;
    font-size: 12px;
    color: rgb(74, 74, 74);
    margin-bottom: 0.25em;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &__action {
    display: none;
  }
  &__list-count {
    width: 100%;
    display:flex;
    justify-content: space-between;
    margin-top: 0.5em;

    &-item{
      display: flex;
      font-size: 10.5px;
      flex-direction: column;
      :nth-child(1) {
        font-weight: 500;
        color: rgb(74, 74, 74);
      }
      :nth-child(2) {
        font-weight: normal;


      }
    }
  }


  &__donation-collected {
    display: block;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;

    &__day-left {
      text-align: right;
    }
  }
  &__campaigner {
    display: flex;
    width: 100%;
    vertical-align: middle;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.8);
    -webkit-box-align: center;
    align-items: center;
    margin: 0.5em 0em;
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    img {
      height: 12px;
      margin-left: 0.25em;
    }
  }
}
.card-small {
  background-color: white;
  line-height: 1.25em;
  position: relative;
  display: flex;
  flex-grow: 1;
  display: block;
  min-width: 155px;
  margin: 0px 10px 0 0px;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 4px;
  &__image {
    border-radius: 4px 4px 0 0;
    width: 100%;
    height: 130px;
    object-fit: cover;
    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin: 0px;
      flex: 1 1 0%;
    }
  }
  &__meta {
    padding: 8px;
  }
  &__title {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 600;
    min-height: 40px;
    max-width: 162px;
    font-size: 12px;
    margin-bottom: 9px;
  }
  &__donation-collected {
    display: block;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 9px;
    margin-top: 9px;
  }
  &__campaign-avatar{
    position: absolute;
    width: 50%;
    top: 0;
    right: 0;
    width: 50px;
    height: 20px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 3px 0 4px;

    img {
      object-fit: cover;
      height: 15px;
      width: 27px;

      &.img-default {
        object-fit: fill !important;
      }
    }
  }

  &__gap:last-child {
    min-width: 4px;
    margin: 4px;
  }
}
.card-large {
  background-color: white;
  line-height: 1.25em;
  position: relative;
  display: flex;
  flex-grow: 1;
  display: block;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 4px;
  &__image {
    border-radius: 4px 4px 0 0;
  }
  &__meta {
    display: flex;
    padding: 10px 15px;
    align-items: center;
    justify-content: space-between;
    &-info {
      width: 65%;
    }
  }

  &__campaign-avatar{
    position: absolute;
    width: 50%;
    top: 0;
    right: 0;
    width: 75px;
    height: 30px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 3px 0 4px;

    img {
      object-fit: cover;
      height: 24px;
      max-width: 44px;
    }
  }
  &__title {
    font-weight: 600;
    display: block;
    font-size: 12px;
    line-height: 17px;
  }
  &__donation-collected {
    display: block;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: #FCA234;
  }
  &__action {
    width: 100px;
  }
}
