.campaign-detail-update {
  height: 100vh;
  &__wrapper {
    background-color: #fff;
    max-width: 480px;
    width: 100%;
    padding: 16px;
    margin: 56px auto 0;
  }
  &__header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
    padding: 0 16px;
    background:#fff;
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    &-wrapper {
      display: flex;
      max-width: 480px;
      align-items: center;
      width: 100%;
      padding: 16px 0;
      margin: 0 auto;
    }
    &-title {
      font-size: 16px;
      margin: 0 0 0 20px;
      font-weight: 500;
    }
  }
}
