.carousel {
  overflow: hidden;
  margin-left: -13px;
  a {
    display: block!important;
  }
  img {
    max-width: 100%;
    width: 100%;
    height: 170px;
    border-radius: 5px;
    display:block;
    max-width: 480px;
    margin: 0 auto;
    object-fit: cover;
  }
}
