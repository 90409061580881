.progress-bar {
  &__track {
    position: relative;
    width: 100%;
    border-radius: 10px;
    box-shadow: inset 0 0 #000
  }
  &__filler {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 2px;
  }
}